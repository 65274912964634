import React from "react";
import { getNamespace, onLoadNamespace } from "./namespaces";
import { getCurrentLanguage } from "./language";
import { getReactTranslate, getTranslate, parseKey } from "./keys";
import { isDev, TFunction, TReactFunction, useLanguage } from "./index";
import { onChangeDebug } from "./debug";
import { useTranslationSuspectContext } from "./TranslationSuspectContext";
import { useUpdateEffect } from "react-use";

export const useTranslation = (): { t: TFunction } => {
  const [trackRef, value, pendingNsRef] = prepareTranslationContext();

  return React.useMemo(() => ({
    t: (key, variables) => {
      if (!key) return "";
      const { ns } = parseKey(key);

      if (!trackRef.current[key]) trackRef.current[ns] = true;

      const namespace = getNamespace(ns, true);
      if (!namespace) pendingNsRef.current[ns] = true;

      return getTranslate(key, variables);
    },
  }), [value]);
};

export const useReactTranslation = (): { t: TReactFunction } => {
  const [trackRef, value, pendingNsRef] = prepareTranslationContext();

  return React.useMemo(() => ({
    t: (key, variables, tags) => {
      if (!key) return [];
      const { ns } = parseKey(key);

      if (!trackRef.current[key]) trackRef.current[ns] = true;

      const namespace = getNamespace(ns, true);
      if (!namespace) pendingNsRef.current[ns] = true;

      return getReactTranslate(key, variables, tags);
    },
  }), [value]);
};

const prepareTranslationContext = (): [React.MutableRefObject<Record<string, boolean>>, number, React.MutableRefObject<Record<string, boolean>>] => {
  const translationSuspectContext = useTranslationSuspectContext();
  const language = useLanguage();
  const [value, setValue] = React.useState(0);
  const trackRef = React.useRef<Record<string, boolean>>({});
  if (!trackRef.current) trackRef.current = {};

  useUpdateEffect(() => {
    setValue((v) => v + 1);
  }, [language]);

  const pendingNsRef = React.useRef<Record<string, boolean>>({});

  React.useEffect(() => {
    translationSuspectContext?.addNamespaces(Object.keys(trackRef.current));

    let needReload = false;
    for (const ns in trackRef.current) {
      const namespace = getNamespace(ns, true);
      if (namespace) {
        needReload = true;
        delete trackRef.current[ns];
      }
    }
    if (needReload) setValue((v) => v + 1);

    return onLoadNamespace(({ name, lng }) => {
      if (lng !== getCurrentLanguage()) return;
      if (trackRef.current[name]) setValue((v) => v + 1);
    });
  }, []);

  if (isDev) React.useEffect(() => onChangeDebug(() => setValue((v) => v + 1)), []);

  return [trackRef, value, pendingNsRef];
};