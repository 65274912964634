import React from "react";
import { getCurrentLanguage, onChangeLanguage } from "./language";

export const useLanguage = () => {
  const [lng, setLng] = React.useState(getCurrentLanguage());

  React.useEffect(() => onChangeLanguage(({ lng }) => setLng(lng)), []);

  return lng;
};
